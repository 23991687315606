import { useState } from 'react';
import { AxiosPrivate } from '../utils/axios';
import { UserModel } from 'ors-frontend/src/models';

export const useGetUsers = () => {
  const [isFetching, setFetching] = useState(false);
  const [users, setUsers] = useState<UserModel[]>([]);

  const getUsers = async (organizationId: string) => {
    try {
      setFetching(true);
      const { data } = await AxiosPrivate.get(
        `/organizations/${organizationId}/users`,
      );

      setUsers(data);
      setFetching(false);

      return data;
    } catch (e) {
      setFetching(false);
    }
  };

  const getUser = async (id) =>{
    try {
      setFetching(true);
      const response = await AxiosPrivate.get(`/users/${id}`);
      setFetching(false);
      return response;
    } catch (e) {
      setFetching(false);
    }
  }

  return {
    isFetching,
    users,
    getUsers,
    getUser,
  };
};
