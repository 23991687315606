import * as yup from 'yup';
import { Utils } from '@mvrb-frontend/shared-ui';

const richTextContentValidation = config => {
  return yup
    .mixed()
    .required(config.EMPTY_RICH_TEXT_ERROR_MESSAGE)
    .transform(richTextInput => {
      return Utils.richTextContentToPlainText(richTextInput);
    })
    .test('transformation', config.TRANSFORMATION_ERROR_MESSAGE, value => {
      return !(value instanceof Error);
    })
    .test('string type', config.TYPE_ERROR_MESSAGE, value => {
      return typeof value === 'string' || value instanceof String;
    })
    .test(
      'non-empty richText content',
      config.EMPTY_RICH_TEXT_CONTENT_ERROR_MESSAGE,
      value => {
        return Boolean(value);
      },
    )
    .test('max length', `Must be ${config.max} characters or less`, value => {
      return value?.length < config.max;
    });
};

export const AddReplySchema = yup.object().shape({
  organizationId: yup.string().optional().nullable(),
  proponentId: yup.string().required('Reviewer is required').nullable(),
  description: richTextContentValidation({
    max: 30000,
    TRANSFORMATION_ERROR_MESSAGE: 'Rich Text parsing error',
    TYPE_ERROR_MESSAGE: 'Description should be a string',
  }),
  parentId: yup.string(),
  commentId: yup.string(),
  status: yup.string(),
  attachments: yup.array(),
});

export const NewsLetterSchema = yup.object().shape({
  username: yup.string().required('Name is required'),
  email: yup.string().email('Must be an email address').required('Email is required'),
  agree: yup.boolean().oneOf([true], "Please agree before continue"),
  options: yup.object().shape({
    lwb: yup.bool(),
    rboard: yup.bool(),
  }).test('options', 'Please select at least one board',function(values){
    return values?.lwb || values?.rboard;
  })
});
