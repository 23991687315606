import {
  isJsonString,
  parsePlainTextToJSON,
} from '@mvrb-frontend/shared-ui/lib/utils/utils';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { useState } from 'react';

import { AxiosPrivate, AxiosPublic } from '../utils/axios';

export const useGetCommentSet = () => {
  const [isFetching, setFetching] = useState(false);

  const getCommentSetsSummary = async (
    reviewId: string,
    published = true,
  ): Promise<any[] | null> => {
    try {
      setFetching(true);
      const { data } = published
        ? await AxiosPublic.get(`/comment-set/summary/public/${reviewId}`)
        : await AxiosPrivate.get(`/comment-set/summary/${reviewId}`);

      return data;
    } catch (e) {
      return null;
    } finally {
      setFetching(false);
    }
  };

  const getCommentSetById = async (
    commentSetId,
    withReplies = false,
  ): Promise<any | null> => {
    try {
      const params = {
        withReplies,
      };
      const url = `/comment-set/${commentSetId}`;

      setFetching(true);
      const { data } = await AxiosPublic.get(url, { params });

      if (data.comments.length > 0) {
        data.comments.forEach(
          c => (c.customTopic = parsePlainTextToJSON(c.customTopic)),
        );
      }

      return data || null;
    } catch (e) {
      return null;
    } finally {
      setFetching(false);
    }
  };

  return {
    isFetching,
    getCommentSetsSummary,
    getCommentSetById,
  };
};
