import { useState } from 'react';

import { AxiosPrivate } from '../utils/axios';
import ResponseException from '../models/ResponseException';

export const useUploadResponseAttachments = () => {
  const [uploadingErrors, setUploadErrors] = useState<string[]>([]);
  const [isUploadingAttachments, setUploading] = useState(false);

  return {
    isUploadingAttachments,
    uploadingErrors,
    uploadAttachments: async (file: any, name: string) => {
      try {
        setUploading(true);
        setUploadErrors([]);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);
        const res = await AxiosPrivate.post('/response-attachments', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        return res;
      } catch (e) {
        setUploadErrors(new ResponseException(e).getErrorMessage());
      } finally {
        setUploading(false);
      }
    },
  };
};
