import { useState } from 'react';
import { AxiosPublic } from '../utils/axios';
import { getRelatedReviews } from '../utils/reviews';
import { ReviewQueryParameters } from '@mvrb-frontend/shared-ui';

export const useReviews = () => {
  const [hasReviewError, setError] = useState(false);
  const [isFetchingReviews, setFetching] = useState(false);

  return {
    isFetchingReviews,
    hasReviewError,
    getReview: async (id, queryParams?: ReviewQueryParameters) => {
      try {
        setFetching(true);
        const response = await AxiosPublic.get(`/reviews/${id}`, {
          params: queryParams,
        });
        return response;
      } catch (e) {
        setError(e);
      } finally {
        setFetching(false);
      }
    },
    getReviews: async filter => {
      try {
        setFetching(true);
        const response = await AxiosPublic.get(`/reviews${filter}`);
        return response;
      } catch (e) {
        setError(e);
      } finally {
        setFetching(false);
      }
    },
    getRelatedReviews: async (id, isPublic = true) => {
      try {
        const res = await getRelatedReviews(id, isPublic);
        return res;
      } catch (e) {
        setError(e);
      }
    },
    getBoardReviews: async (boardId, filter) => {
      try {
        setFetching(true);
        const response = await AxiosPublic.get(
          `/boards/${boardId}/reviews${filter}`,
        );
        return response;
      } catch (e) {
        setError(e);
      } finally {
        setFetching(false);
      }
    },
  };
};
