import { useState } from "react"
import { AxiosPrivate, AxiosPublic } from "../utils/axios"

export const useGetEngagements = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [engagementList, setEngagementList] = useState<any>([]);
  const [count, setCount] = useState(0)

  const getEngagements = async (queryParams?: any) => {
    setIsFetching(true);
    try {
      const { data } = await AxiosPublic.get(`/organizations/engagement/list`,{
        params: {...queryParams},
      });

      setEngagementList(data.engagementData);
      setCount(data.dataCount)

      return data.engagementData
    } catch (e) {
      setIsFetching(false);
    }
    finally {
      setIsFetching(false);
    }
  }


  return{
    getEngagements,
    isFetching,
    engagementList,
    count,
  }
}