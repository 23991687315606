import { useState } from 'react';
import { AxiosPublic } from '../utils/axios';
import { NewsLetterParams, NewsLetterSubmissionType } from '@mvrb-frontend/shared-ui';
import NewsLetterModel from '../models/NewsLetterModel';
import ResponseException from '../models/ResponseException';

export const useNewsLetterApi = ()=>{
  const [isFetching, setFetching]= useState<boolean>(false);
  const [dataNewsLetter, setDataNewsLetter] = useState([]);
  const [ apiSuccess, setApisuccess] = useState<boolean>(false);
  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const [showNL, setShowNL] = useState<boolean>(false);
  const [ listFile, setListFile ] = useState<any>();
  const [ userCount, setUserCount ] = useState<number>(0);

  const updateUserNewsLetter = async (payload: NewsLetterModel, submissionType: NewsLetterSubmissionType) =>{
    try {
      setApisuccess(false);
      switch(submissionType){
        case NewsLetterSubmissionType.Submit:
          await AxiosPublic.post('/newsletter', payload);
        break;
        case NewsLetterSubmissionType.Update:
          await AxiosPublic.patch('/newsletter', payload);
        break;
        case NewsLetterSubmissionType.UpdatePreferences:
          await AxiosPublic.patch('/newsletter/user-preferences', payload);
        break;
      }
      
      setApiErrors([]);
    } catch (error) {
      setApiErrors(new ResponseException(error).getErrorMessage());
    }
    finally{
      setApisuccess(true);
    }
  }

  const getNewsLetters = async (queryParams: NewsLetterParams) =>{
    try {
      setFetching(true);
      const {data} = await AxiosPublic.get('/newsletter', {
        params: queryParams
      });
      data.rows.map((item)=>{
        Object.assign(item, {id:item.Email})
      })
      setUserCount(data.userCount);
      setDataNewsLetter(data.rows);
    } catch (error) {
      setApiErrors(new ResponseException(error).getErrorMessage());
    } finally{
      setFetching(false);
    }
  };

  const getUserNewsLetter = async (queryParams: NewsLetterParams) =>{
    try{
      setFetching(true);
      const{data}: any = await AxiosPublic.get('/newsletter/usernewsletter', {
        params: queryParams
      });

      setDataNewsLetter(data);
    }
    catch(error){
      setApiErrors(new ResponseException(error).getErrorMessage());
    }
    finally{
      setFetching(false);
    }
  }

  //-->NewsLetter popup its to only be showed the first time you signin after
  //-->implementation

  //-->Get true if a user have sign in and have already seen the Newsletter popup 
  const getUserPreferences = async (queryParams: NewsLetterParams) =>{
    try{
      setFetching(true);
      const{data}: any = await AxiosPublic.get('/newsletter/user-preferences', {
        params: queryParams
      });
      
      if(data.length>0){
        data[0].Newsletter===true
          ?setShowNL(false)
          :setShowNL(true)
      }
    }
    catch(error){
      setApiErrors(new ResponseException(error).getErrorMessage());
    }
    finally{
      setFetching(false);
    }
  }

  return{
    isFetching,
    dataNewsLetter,
    apiSuccess,
    apiErrors,
    showNL,
    listFile,
    userCount,
    getUserPreferences,
    updateUserNewsLetter,
    getUserNewsLetter,
    getNewsLetters,
  };
}