import { useState } from 'react';
import { AxiosPublic, AxiosPrivate } from '../utils/axios';

export const useReviewDownloads = () => {
  const [isFetching, setFetching] = useState(false);

  const downloadComments = async (
    reviewId: string,
    format: string,
    published: boolean,
  ) => {
    try {
      setFetching(true);
      const response = published
        ? await AxiosPublic.get(
            `/comments/export-published/${reviewId}?format=${format}`,
          )
        : await AxiosPrivate.get(`/comments/export/${reviewId}`);

      return response.data;
    } catch (e) {
      setFetching(false);
    } finally {
      setFetching(false);
    }
  };

  const enqueueDownloadAttachments = async (
    reviewId: string,
    dataFormat: string,
    includeComments = false,
  ) => {
    try {
      setFetching(true);
      const { data } = await AxiosPrivate.post(
        `/comments/download/${reviewId}`,
        { includeComments: includeComments, dataFormat: dataFormat },
      );

      return data.referenceId;
    } catch (e) {
      setFetching(false);
    } finally {
      setFetching(false);
    }
  };

  const downloadAttachments = async (referenceId: string) => {
    try {
      setFetching(true);
      const { data } = await AxiosPrivate.get(
        `/comments/download/${referenceId}`,
      );
      return data;
    } catch (e) {
      setFetching(false);
    } finally {
      setFetching(false);
    }
  };

  return {
    isFetching,
    downloadComments,
    enqueueDownloadAttachments,
    downloadAttachments,
  };
};
