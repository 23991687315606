import { OrsRoutes } from './routes';

export const ROWS_PER_PAGE = 12;

export enum ReviewStatusEnum {
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
}

export enum DownloadTypesEnum {
  NONE = 'NONE',
  ALL = 'ALL',
  COMMENTS = 'COMMENTS',
  ATTACHMENTS = 'ATTACHMENTS',
}

export enum CommentStatusEnum {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export enum CommentTypeEnum {
  Default = 'default',
  Audio = 'audio',
}

export enum ActionType {
  EDIT = 'Edit',
  VIEW = 'View',
}

export const SORT_ORDER = ['DESC', 'ASC'];

export const DownloadFormatOptions = [
  { value: '.docx', label: 'Microsoft Word format' },
  { value: '.pdf', label: 'Adobe PDF' },
  { value: 'staff.xlsx', label: 'Microsoft Excel document' },
];

export const DownloadOptions = [
  { type: DownloadTypesEnum.NONE, label: 'Review Summary Download Options' },
  {
    type: DownloadTypesEnum.ALL,
    label: 'All Comments with Attachments',
    menuOption: true,
    showIfAttachments: true,
  },
  {
    type: DownloadTypesEnum.COMMENTS,
    label: 'Comments only',
    menuOption: true,
  },
  {
    type: DownloadTypesEnum.ATTACHMENTS,
    label: 'Attachments only',
    menuOption: true,
    showIfAttachments: true,
  },
];

export const CommentSizeBase = {
  '.pdf': 0.03,
  '.docx': 0.007,
  '.xlsx': 0.017,
};

export const CommentSizeFactor = {
  '.pdf': 0.0015,
  '.docx': 0.0005,
  '.xlsx': 0.0025,
};

export const Types = [
  { value: 'All', label: 'All' },
  { value: 'Review', label: 'Item for Review' },
  { value: 'IR', label: 'Information Request' },
];

export const Statuses = [
  { value: 'All', label: 'All' },
  { value: 'Draft', label: 'Draft' },
  { value: 'Active', label: 'Active' },
  { value: 'Review', label: 'Review' },
  { value: 'Completed', label: 'Completed' },
];

export const PublicStatuses = [
  { value: 'All', label: 'All' },
  { value: 'Active', label: 'Active' },
  { value: 'Completed', label: 'Completed' },
];
export const StaffStatuses = [
  { value: 'All', label: 'All' },
  { value: 'Draft', label: 'Draft' },
  { value: 'Active', label: 'Active' },
  { value: 'Completed', label: 'Completed' },
];

export const DateRanges = [
  { value: 'All', label: 'All' },
  { value: '1', label: 'Last 30 Days' },
  { value: '6', label: 'Last 6 months' },
  { value: '12', label: 'Last 12 months' },
];

export const MenuItems = [
  { value: '#overview', label: 'Overview' },
  { value: '#documents', label: 'Documents' },
  { value: '#comments', label: 'Comments' },
];

export const ReviewListColumns = [
  {
    id: 'name',
    label: 'Project',
    isSortable: false,
    style: { width: '240px' },
  },
  {
    id: 'organization',
    label: 'Proponent',
    isSortable: true,
  },
  {
    id: 'title',
    label: 'Item for Review /\nInformation Request',
    isSortable: false,
    style: { minWidth: '135px' },
    link: data => `/review/${data.id}`,
  },
  {
    id: 'commentDueAt',
    label: 'Comments Due',
    isSortable: true,
    isDate: true,
    style: { width: 'auto' },
  },
  {
    id: 'responseDueAt',
    label: 'Responses Due',
    isSortable: true,
    isDate: true,
    style: { width: 'auto' },
  },
  { id: 'status', label: 'Status', isSortable: true, style: { width: 'auto' } },
];

export const ReviewActions = [
  {
    label: ActionType.VIEW,
    onClick: id => {
      window.open(`/review/${id}`);
    },
    isHidden: status => {
      return false;
    },
  },
];

export const StaffReviewActions = [
  {
    label: ActionType.VIEW,
    onClick: id => {
      window.open(`/review/${id}`);
    },
    isHidden: status => {
      return false;
    },
  },
  {
    label: ActionType.EDIT,
    onClick: id => {
      window.open(`${OrsRoutes.EditReview.replace(':id', id)}`, '_self');
    },
    isHidden: status => {
      return false;
    },
  },
];
