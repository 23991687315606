import { useState } from 'react';

import { AxiosPrivate } from '../utils/axios';
import ResponseException from '../models/ResponseException';

export const useResponses = () => {
  const [resErrors, setResErrors] = useState<string[]>([]);
  const [isSubmitingResponse, setSubmiting] = useState(false);

  return {
    isSubmitingResponse,
    resErrors,
    submitResponses: async values => {
      try {
        setSubmiting(true);
        const res = await AxiosPrivate.post('/responses/reply', values);
        return res;
      } catch (e) {
        setResErrors(new ResponseException(e).getErrorMessage());
      } finally {
        setSubmiting(false);
      }
    },
  };
};
