import { AxiosPublic, AxiosPrivate } from '../utils/axios';
import { ROWS_PER_PAGE, SORT_ORDER, ReviewStatusEnum } from '../constants';
import { isAdminUser, isStaffUser } from '../utils/auth';

export const getRelatedReviews = async (id, isPublic = true) => {
  try {
    let response = null;
    if (isPublic) {
      response = await AxiosPublic.get(`/reviews/${id}/related/public`);
    } else {
      response = await AxiosPrivate.get(`/reviews/${id}/related/`);
    }
    return response;
  } catch (e) {
    // TODO: set alert message
  }
};

export const generateQuery = (
  searchFilter = '',
  typeFilter = 'All',
  statusFilter = 'All',
  dateFilter = 'All',
  organizationtFilter = '',
  offset = 0,
  orderBy = 'defaultStatus',
  order = SORT_ORDER[0],
) => {
  let base = `?take=${ROWS_PER_PAGE}&skip=${offset}&orderBy=${
    orderBy ? orderBy : 'defaultStatus'
  }&order=${order}`; // pagination
  if (searchFilter !== '') base += `&name=${searchFilter}`; // search by name
  // filter by status (eg. Avtive, Completed, ...)
  if (statusFilter !== 'All') base += statusFilter;
  else {
    if (isStaffUser()||isAdminUser())
      base += `&status=${ReviewStatusEnum.ACTIVE}&status=${ReviewStatusEnum.COMPLETED}&status=${ReviewStatusEnum.DRAFT}`;
  }
  if (typeFilter !== 'All') base += `&type=${typeFilter}`; // filter by type (eg. Permit, EA, ...)
  if (dateFilter !== 'All') base += `&dateRange=${dateFilter}`; // filter by date range (eg. Last week, Last month, ...)
  if (organizationtFilter !== '')
    base += `&organizationId=${organizationtFilter}`; // search by organization name
  return base;
};
